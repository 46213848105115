import { isTestEnv } from "@telia/b2b-utils";
import {
  COTS_PROD_DOMAINS,
  COTS_TEST_DOMAINS,
  TELIA_SE_PROD_DOMAINS,
  TELIA_SE_TEST_DOMAINS,
} from "@telia/b2x-telia-se-domain-names";

export const DEV_FORCE_NTSE_COOKIE_VALUE = "1-lock";
export const DEV_FORCE_TSE_COOKIE_VALUE = "0-lock";
export const NTSE_COOKIE_NAME = "telia-canary";
const _TEST_TRIGGER_HOSTS = [...TELIA_SE_TEST_DOMAINS, ...COTS_TEST_DOMAINS];

export const TRIGGER_HOSTS = [...TELIA_SE_PROD_DOMAINS, ...COTS_PROD_DOMAINS].concat(
  isTestEnv() ? _TEST_TRIGGER_HOSTS : []
);
