import {
  getCookieValue,
  getCurrentHost,
  isForcingNtse,
  reloadBrowser,
  setNtseCookie,
  setTseCookie,
} from "./util";
import {
  DEV_FORCE_NTSE_COOKIE_VALUE,
  DEV_FORCE_TSE_COOKIE_VALUE,
  NTSE_COOKIE_NAME,
  TRIGGER_HOSTS,
} from "./constants";
import { addNtseUrls, getNtseUrls, isUrlInNtse } from "@telia/b2b-utils";

export const isTsePage = (ntseUrlRegexes: Array<RegExp>, pathname: string): boolean => {
  return !isUrlInNtse(ntseUrlRegexes, pathname) && pathname.includes("/hantera/overvakning-larm");
};

const gotoTse = (pathname?: string): void => {
  setTseCookie();
  reloadBrowser(pathname);
};

const gotoNtse = (pathname?: string): void => {
  setNtseCookie();
  reloadBrowser(pathname);
};

const ntseUrlRegexes: Array<RegExp> = addNtseUrls(getNtseUrls());

export function multipassBeforeRoutingEventHandler(event) {
  const { newUrl, oldUrl, cancelNavigation, navigationIsCanceled } = event.detail;
  const url = new URL(newUrl);
  if (
    // @ts-expect-error Temporary fix until https://github.com/single-spa/single-spa/issues/905
    !window.ignoreNavigationTemp &&
    !navigationIsCanceled &&
    !isForcingNtse() &&
    isTsePage(ntseUrlRegexes, url.pathname)
  ) {
    // @ts-expect-error Temporary fix until https://github.com/single-spa/single-spa/issues/905
    window.ignoreNavigationTemp = true;
    cancelNavigation();
    // eslint-disable-next-line no-console
    console.log(
      `Multipass: Current URL ${newUrl} is not in ntse, going to tse. Old url was ${oldUrl}`
    );
    gotoTse(url.pathname);
  }
}

export const bootstrap = (): Promise<void> => {
  return Promise.resolve();
};

export const mount = (): Promise<void> => {
  const ntseCookieValue = getCookieValue(NTSE_COOKIE_NAME);
  const ntseCookieActive = ntseCookieValue?.startsWith("1");
  const urlHasNtsePage = isUrlInNtse(ntseUrlRegexes, window.location.pathname);
  const urlIsTsePage = isTsePage(ntseUrlRegexes, window.location.pathname);

  if (TRIGGER_HOSTS.includes(getCurrentHost())) {
    // eslint-disable-next-line no-console
    console.log("Multipass: Active");
    if (
      ntseCookieValue === DEV_FORCE_TSE_COOKIE_VALUE ||
      ntseCookieValue === DEV_FORCE_NTSE_COOKIE_VALUE
    ) {
      // do nothing, let user stay wherever we are
    } else if (ntseCookieActive) {
      // eslint-disable-next-line no-console
      console.log("Multipass: We are in ntse");

      if (urlIsTsePage) {
        gotoTse();
      }

      window.addEventListener(
        "single-spa:before-routing-event",
        multipassBeforeRoutingEventHandler
      );
    } else {
      // eslint-disable-next-line no-console
      console.log("Multipass: We are in tse");

      if (urlHasNtsePage) {
        // eslint-disable-next-line no-console
        console.log("Multipass: Current URL is in ntse, going to ntse.");

        gotoNtse();
      }
    }
  } else {
    // eslint-disable-next-line no-console
    console.log("Multipass: Inactive");
  }
  return Promise.resolve();
};

export const unmount = (): Promise<void> => {
  window.removeEventListener("single-spa:before-routing-event", multipassBeforeRoutingEventHandler);
  return Promise.resolve();
};
